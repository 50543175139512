import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../services/api";
import { HiPencil } from "react-icons/hi";
import Select from "react-select";
import { GrDocumentUser } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button } from "react-bootstrap";

import dayjs from "dayjs";
import { MdCancel } from "react-icons/md";

export default function ListarPermissoes() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const [requestPerfisOptions, setrequestPerfisOptions] = useState([]);
  const [erroPage, setErrorPage] = useState(true);
  const [DataPermissoes, setDataPermissoes] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);

  const [dataBusca, setDataBusca] = useState();
  const [checkboxBusca, setcheckboxBusca] = useState(false);
  const [statusBusca, setStatusBusca] = useState("");

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleRequestBusca(event) {
    console.log(event);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes?descricao=${
          event?.descricao
        }&codigoPerfil=${event.perfil?.value ? event.perfil?.value : ""}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataPermissoes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleClick() {
    navigate("/Cadastra-Permissoes"); //
  }
  async function getDataPefil() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro.map((item) => {
          return { label: item?.Descricao, value: item?.Codigo };
        });
        setrequestPerfisOptions(Options);
      }

      setErrorPage(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filtro = response.data.filter(
          (item) => item?.Perfil?.Status !== "Deletado"
        );
        setDataPermissoes(filtro);
      }
      setErrorPage(false);
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeApi(DataPermissoes[key].Codigo);
        const items = [...DataPermissoes];
        items.splice(key, 1);
        setDataPermissoes(items);
        MySwal.fire("Excluído!", "A Permissão foi deletada.", "success");
      }
    });
  }
  async function removeApi(code) {
    try {
      await api.delete(`/api/v1.0/permissoes/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
    getDataPefil();
  }, []);

  return (
    <>
      {!erroPage ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Permissões  - Módulo De Planejamento"
            button={{ text: "+ Gestão de perfis" }}
            onClick={handleClick}
          />

          <main className="listagem-inicial">
            <Formik
              initialValues={{
                descricao: "",
                perfil: "",
              }}
              onSubmit={handleRequestBusca}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-5   ">
                    <div className="formPCA gap-5 ">
                      <div className=" w-25">
                        <Field
                          type="text"
                          name="descricao"
                          placeholder="Descrição"
                          className="form-control inputForm "
                        />
                      </div>
                      <div className=" w-25">
                        <Select
                          className="w-100"
                          name="perfil"
                          value={values.perfil}
                          placeholder="Perfil"
                          options={requestPerfisOptions}
                          onChange={(selectedOptions) => {
                            setFieldValue("perfil", selectedOptions);
                          }}
                        />
                      </div>
                      <div className="col">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container pt-5">
              <table className="table">
                <tbody>
                  <tr className="">
                    <th>Descrição</th>
                    <th>Código</th>
                    <th>Perfil</th>
                    <th>Ações</th>
                  </tr>

                  {DataPermissoes?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{item?.Descricao}</td>
                      <td>{item?.Papel}</td>
                      <td>{item?.Perfil?.Descricao}</td>
                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() =>
                              navigate(
                                `/Cadastra-Permissoes?code=${item.Codigo}`
                              )
                            }
                          />
                          <MdCancel
                            type="button"
                            style={{ color: "red" }}
                            onClick={() => RemoveItem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
