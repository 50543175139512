import withReactContent from "sweetalert2-react-content";
import styles from "./AlterarPerfilUsuario.scss";

import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HiPencil } from "react-icons/hi";
import Footer from "../../../components/Footer/Footer";
import * as jose from "jose";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";
import { ModalSuccess } from "../../../components/ModalSuccess/ModalSuccess";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import * as Yup from "yup";
export default function AlterarPerfilUsuario() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [Load, setLoad] = useState(false);
  const [DataUsuario, setDataUsuario] = useState([]);
  const [teste, setTEste] = useState([1, 2, 3]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const [Code, setCode] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const [dataBusca, setDataBusca] = useState();

  const [statusBusca, setStatusBusca] = useState("");
  const [requestPerfis, setrequestPerfis] = useState([]);
  const [checkboxActive, setCheckboxActive] = useState([]);
  const [RelacaoPerfilPermissao, setRelacaoPerfilPermissao] = useState([]);
  const [NewInpult, setNewInpult] = useState(true);
  const [init, setInit] = useState({});
  const [DataPermissoes, setDataPermissoes] = useState([]);
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleSubmit(event) {
    const objetosFiltrados = Object.entries(event).reduce(
      (acc, [chave, valor]) => {
        if (chave.includes("perfilCheck")) {
          acc.push({ [chave]: valor });
        }
        return acc;
      },
      []
    );
    const codigosTrue = objetosFiltrados.reduce((acc, obj) => {
      const [chave, valor] = Object.entries(obj)[0];

      if (valor === true && chave.startsWith("perfilCheck-")) {
        const codigo = chave.split("perfilCheck-")[1];
        acc.push(codigo);
      }

      return acc;
    }, []);
    const novoArray = RelacaoPerfilPermissao.filter((objeto) => {
      const perfilCodigo = objeto.Perfil.Codigo;
      return codigosTrue.includes(perfilCodigo);
    });

    //========================================================
    const objetosFiltradosPermissao = Object.entries(event).reduce(
      (acc, [chave, valor]) => {
        if (chave.includes("permissionCheck")) {
          acc.push({ [chave]: valor });
        }
        return acc;
      },
      []
    );
    const codigosTruePermissao = objetosFiltradosPermissao.reduce(
      (acc, obj) => {
        const [chave, valor] = Object.entries(obj)[0];

        if (valor === true && chave.startsWith("permissionCheck-")) {
          const codigo = chave.split("permissionCheck-")[1];
          acc.push(codigo);
        }

        return acc;
      },
      []
    );

    //===============================================================

    const objetosFiltradosAto = Object.entries(event).reduce(
      (acc, [chave, valor]) => {
        if (chave.includes("Ato")) {
          acc.push({ [chave]: valor });
        }
        return acc;
      },
      []
    );
    const arrayAtos = objetosFiltradosAto?.map((objeto) => {
      const novaChave = Object.keys(objeto)[0].replace("Ato-", "");
      const novoObjeto = { [novaChave]: objeto[Object.keys(objeto)[0]] };
      return novoObjeto;
    });

    //================================================================

    const novoArray2 = novoArray.map((objeto) => {
      const perfil = objeto.Perfil;

      const permissoesFiltradas = objeto.permissoes.filter((permissao) =>
        codigosTruePermissao.includes(permissao.Codigo)
      );

      return { Perfil: perfil, permissoes: permissoesFiltradas };
    });

    let perfil = novoArray2?.map((item) => {
      const CodigoPerfil = item.Perfil.Codigo;
      const AtoLegal =
        arrayAtos?.find((objeto) => objeto[CodigoPerfil])?.[CodigoPerfil] ||
        null;

      return {
        AtoLegal: AtoLegal,
        CodigoPerfil: CodigoPerfil,
      };
    });
    let permissao = novoArray2
      ?.map((item) => {
        var perm = item.permissoes.map((permissao) => ({
          CodigoPermissao: permissao.Codigo,
        }));
        return perm;
      })
      .flat();

    if (NewInpult) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/usuarios`,
          {
            Codigo: Code,
            UsuarioPerfil: perfil,
            UsuarioPermissao: permissao,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("Os perfis do Usuario foram atualizados");

        navigate("/Lista-Usuarios");
      } catch (error) {
        ModalErrorMessage(error);
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/usuarios`,
          {
            Codigo: Code,
            UsuarioPerfil: perfil,
            UsuarioPermissao: permissao,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("Os perfis do Usuario foram atualizados");

        // ActionRetornarButton();
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalErrorMessage(error);
      }
    }
  }
  async function handleClick() {
    navigate("/Materiais/Cadastra"); //
  }
  async function getData(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/usuarios/${code}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (response.data) {
        setDataUsuario(response.data);

        let PermissionActives = [];
        const initialValues = response.data.Roles.reduce((acc, currRole) => {
          const permissionRoleValues = (currRole.PermissionRoles || []).reduce(
            (roleAcc, permissionRole) => {
              return {
                ...roleAcc,
                [`permissionCheck-${permissionRole.Codigo}`]: true,
              };
            },
            {}
          );

          PermissionActives.push({ [`${currRole.Codigo}`]: true });

          return {
            ...acc,
            [`perfilCheck-${currRole.Codigo}`]: true,
            ...permissionRoleValues,
            [`Ato-${currRole.Codigo}`]: currRole.AtoLegal,
          };
        }, {});
        const transformedArray = PermissionActives.reduce((acc, obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          return {
            ...acc,
            [key]: value,
          };
        }, {});

        setCheckboxActive(transformedArray);
        setNewInpult(false);
        setInit(initialValues);
        setLoad(true);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataPerfil() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setrequestPerfis(response.data);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getDataPermissao() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataPermissoes(response.data);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function schemaCreate(data) {
    const schemaObject = {};

    data.map((item) => {
      schemaObject[`Ato-${item.Perfil.Codigo}`] = Yup.string().when(
        [`perfilCheck-${item.Perfil.Codigo}`],
        {
          is: (profileCheckbox) => profileCheckbox, // A validação é aplicada quando profileCheckbox é verdadeiro
          then: Yup.string().required("O Ato Legal é obrigatório"),
          otherwise: Yup.string(), // Sem validação quando profileCheckbox é falso
        }
      );
    });
    return Yup.object().shape(schemaObject);
  }

  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      getData(code);
      getDataPerfil();
      getDataPermissao();
      setCode(code);
    } else {
      setLoad(true);
    }
  }, []);
  function VerificaAtivo(codigo) {
    let value = checkboxActive[codigo];

    return value;
  }
  useEffect(() => {
    let value = requestPerfis.map((perfil) => {
      let permis = DataPermissoes.filter((permissoe) => {
        return permissoe.Perfil.Codigo === perfil.Codigo;
      });
      return { Perfil: perfil, permissoes: permis };
    });
    setRelacaoPerfilPermissao(value);
  }, [DataPermissoes]);
  // useEffect(() => {
  //   console.log(checkboxActive);
  // }, [checkboxActive]);
  return (
    <>
      {Load ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Usuários - Módulo De Planejamento"
          />
          <div className="container mt-5">
            <h3>Alterar perfil de acesso - {DataUsuario.Nome}</h3>
          </div>
          <main className="listagem-inicial">
            <Formik
              initialValues={init}
              onSubmit={handleSubmit}
              validationSchema={schemaCreate(RelacaoPerfilPermissao)}
              validateOnBlur={false}
              validateOnChange={false} // Para validar apenas ao submeter
              validateOnMount={false} // Para validar apenas ao submeter
            >
              {({ setFieldValue, values, errors }) => (
                <Form>
                  <div className="container ContainerFormPerfil  ">
                    <div className=" w-100">
                      <div className="groupCheckHorizontal  ">
                        {RelacaoPerfilPermissao?.map((item, index) => {
                          return (
                            <div key={index} className="containerPerfilSelect">
                              <div>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`perfilCheck-${item.Perfil.Codigo}`}
                                      checked={
                                        values[
                                          `perfilCheck-${item.Perfil.Codigo}`
                                        ]
                                      }
                                      onChange={(e) => {
                                        const { name, checked } = e.target;

                                        setCheckboxActive({
                                          ...checkboxActive,

                                          [`${item.Perfil.Codigo}`]: checked,
                                        });

                                        setFieldValue(
                                          `perfilCheck-${item.Perfil.Codigo}`,
                                          checked
                                        );
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                      // onChange={handleChange}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={item.Perfil.Descricao}
                                />
                                <div className="groupCheckVertical">
                                  {item?.permissoes.map((permissao, index) => {
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        control={
                                          <Checkbox
                                            disabled={
                                              !VerificaAtivo(
                                                permissao.Perfil.Codigo
                                              )
                                            }
                                            checked={
                                              values[
                                                `permissionCheck-${permissao.Codigo}`
                                              ]
                                            }
                                            onChange={(e) => {
                                              const { name, checked } =
                                                e.target;

                                              setFieldValue(
                                                `permissionCheck-${permissao.Codigo}`,
                                                checked
                                              );
                                            }}
                                            name={`permissionCheck-${permissao.Codigo}`}
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 28,
                                              },
                                            }}
                                            // onChange={handleChange}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={permissao?.Descricao}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                              <FormGroup
                                hidden={!VerificaAtivo(item.Perfil.Codigo)}
                                className="mb-3 p-1 formGroup w-25"
                              >
                                <label className="">
                                  <span className="d-flex flex-row align-items-center">
                                    Ato Legal
                                  </span>
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name={`Ato-${item.Perfil.Codigo}`}
                                />
                                {errors[`Ato-${item.Perfil.Codigo}`] && (
                                  <p>{errors[`Ato-${item.Perfil.Codigo}`]}</p>
                                )}
                              </FormGroup>
                            </div>
                          );
                        })}
                      </div>

                      <div className=" btnContainerSubmit gap-3">
                        <button
                          className="btn btn-outline-primary "
                          onClick={() => navigate("/Lista-Usuarios")}
                        >
                          Voltar
                        </button>
                        <button className="btn btn-primary " type="submit">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
