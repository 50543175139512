import withReactContent from "sweetalert2-react-content";

import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar";
import { ErrorMessage, Field, Form, Formik } from "formik";

import Footer from "../../../components/Footer/Footer";
import * as jose from "jose";

import { Button, FormGroup } from "react-bootstrap";
import { ModalSuccess } from "../../../components/ModalSuccess/ModalSuccess";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import Select from "react-select";
import * as yup from "yup";

export default function CadastraPermissoes() {
  const MySwal = withReactContent(Swal);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [requestPerfisOptions, setRequestPerfisOptions] = useState([]);
  const [requestPermissao, setRequestPermissao] = useState([]);
  const [Load, setLoad] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [NewInpult, setNewInpult] = useState(true);
  const [Code, setCode] = useState("");
  const schema = yup.object().shape({
    descricao: yup
      .string()
      .typeError("A descrição e Obrigatória")
      .required("A descrição e Obrigatória "),
    perfil: yup
      .object()
      .typeError("O Perfil e Obrigatório")
      .required("O Perfil e Obrigatório"),
  });
  async function handleSubmit(event) {
    if (NewInpult) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes`,
          {
            Descricao: event.descricao,
            CodigoPerfil: event.perfil?.value,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Permissão foi adicionada");

        ActionRetornarButton();
      } catch (error) {
        ModalErrorMessage(error);
      }
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes`,
          {
            Codigo: Code,
            Descricao: event.descricao,
            CodigoPerfil: event.perfil?.value,
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        );
        ModalSuccess("A Permissão foi Atualizada");

        ActionRetornarButton();
        // setErrorPage(true);
      } catch (error) {
        console.log(error);

        ModalErrorMessage(error);
      }
    }
  }
  async function getDataPerfil() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (response.data) {
        const filtro = response?.data.filter(
          (item) => item.Status !== "Deletado"
        );
        let Options = filtro?.map((item) => {
          return { label: item.Descricao, value: item.Codigo };
        });
        console.log(Options);
        setRequestPerfisOptions(Options);
      }
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  async function getData(Codigo) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/permissoes/${Codigo}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setRequestPermissao(response.data);
      }
      setNewInpult(false);
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function ActionRetornarButton() {
    navigate(`/Listar-Permissoes`);
  }
  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      getDataPerfil();
      getData(code);
      setCode(code);
    } else {
      getDataPerfil();
      setLoad(true);
    }
  }, []);

  return (
    <>
      {Load ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Permissões - Módulo De Planejamento"
          />
          <div className="container mt-5">
            <h3>Cadastrar Permissão de acesso</h3>
          </div>
          <main className="listagem-inicial">
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                descricao: requestPermissao?.Descricao
                  ? requestPermissao.Descricao
                  : "",
                perfil: requestPermissao?.Perfil
                  ? {
                      label: requestPermissao?.Perfil.Descricao,
                      value: requestPermissao?.Perfil.Codigo,
                    }
                  : "",
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form className="container">
                  <div className=" pt-3 ">
                    <div className="formAdd ">
                      <FormGroup className="mb-3 p-1 formGroup w-75">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Descrição
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="descricao"
                        />
                        <ErrorMessage component="p" name="descricao" />
                      </FormGroup>
                      <div className="mb-3 p-1  w-75">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Perfil
                          </span>
                        </label>
                        <Select
                          className="w-100"
                          name="perfil"
                          value={values.perfil}
                          placeholder="Selecione"
                          options={requestPerfisOptions}
                          onChange={(selectedOptions) => {
                            setFieldValue("perfil", selectedOptions);
                          }}
                        />
                        <ErrorMessage component="p" name="perfil" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={() => {}}>Cancelar</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
