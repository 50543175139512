import styles from "./styles.scss";

import { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import AuthContext from "../../contexts/auth";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = useContext(AuthContext);

  return (
    <>
      <Navbar titulo={`Módulo de Planejamento `} linkHome={false} />
      <div className="container mt-5 d-flex gap-5 ">
        <div className="col-sm-6 mb-3 mb-sm-0  ">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Plano de Contratação Anual</h5>
              <p className="card-text">Módulo do PCA e DFD</p>

              <Link
                to={`${process.env.REACT_APP_SUBDOMAIN_PCA}/?token=${state.token}`}
                href="#"
                target="_blank"
                className="btn btn-primary"
              >
                Acessar
              </Link>
            </div>
          </div>

          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">Gestão de Risco</h5>
              <p className="card-text">Módulo de gestão de risco</p>
              <Link
                to={`${process.env.REACT_APP_SUBDOMAIN_RISCO}/?token=${state.token}`}
                href="#"
                target="_blank"
                className="btn btn-primary"
              >
                Acessar
              </Link>
            </div>
          </div>
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">Materiais e Serviços</h5>
              <p className="card-text">Catálogo de Materiais e Serviços</p>
              <Link
                to={`${process.env.REACT_APP_SUBDOMAIN_CMS}/?token=${state.token}`}
                href="#"
                target="_blank"
                className="btn btn-primary"
              >
                Acessar
              </Link>
            </div>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="card ">
            <div className="card-body">
              <h5 className="card-title">Estudo Técnico Preliminar - ETP</h5>
              <p className="card-text">Módulo de ETP</p>
              <Link
                to={`${process.env.REACT_APP_SUBDOMAIN_ETP}/?token=${state.token}`}
                target="_blank"
                href="#"
                className="btn btn-primary"
              >
                Acessar
              </Link>
            </div>
          </div>
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">Termo de Referência</h5>
              <p className="card-text">Modulo do PCA e DFD</p>
              <Link
                to={`/`}
                href="#"
                target="_blank"
                className="btn btn-primary"
              >
                Acessar
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
