import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export function Auth() {
  const { state, dispatch } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const fetchData = async (token, route) => {
    const newRouteRedirect = route || "/Modulos";

    // Dispatch é chamado após a atualização de routeRedirect
    dispatch({
      type: "LOGIN",
      payload: token,
    });

    // Redirecione apenas após o dispatch ter atualizado o estado
    if (newRouteRedirect) {
      navigate(newRouteRedirect);
    }
  };

  useEffect(() => {
    const tokenUrl = new URLSearchParams(window.location.search).get("token");
    const route = new URLSearchParams(window.location.search).get("route");

    // Se tokenUrl for null, retorne sem fazer nada
    // console.log(tokenUrl, state.isAuthenticated);
    // if (!tokenUrl) {
    //   MySwal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "A página atual não conseguiu carregar os dados;\n\n ",
    //     footer: "Erro: Token ausente ",
    //     backdrop: false,
    //     showCancelButton: false,
    //     showConfirmButton: false,
    //   });
    // }

    if (!tokenUrl) {
      return;
    }

    fetchData(tokenUrl, route); // Chama a função assíncrona diretamente
  }, [dispatch, navigate]);

  return <></>;
}
