import withReactContent from "sweetalert2-react-content";

import Swal from "sweetalert2";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/auth";
import axios from "axios";
import Navbar from "../../../components/Navbar/Navbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { HiPencil } from "react-icons/hi";
import Footer from "../../../components/Footer/Footer";
import * as jose from "jose";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup } from "@mui/material";
import { Button } from "react-bootstrap";
import { ModalSuccess } from "../../../components/ModalSuccess/ModalSuccess";
import { ModalError } from "../../../components/ModalError/ModalError";
import { ModalErrorMessage } from "../../../components/ModalErrorMessage /ModalErrorMessage ";
import * as yup from "yup";

export default function EditarPerfil() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [erroPage, setErrorPage] = useState(true);
  const [requestPerfis, setRequestPerfis] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);
  const [Load, setLoad] = useState(false);
  const location = useLocation();
  const [Code, setCode] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const [dataBusca, setDataBusca] = useState();
  const [checkboxBusca, setcheckboxBusca] = useState(false);
  const [statusBusca, setStatusBusca] = useState("");
  const schema = yup.object().shape({
    Descricao: yup
      .string()
      .typeError("A descrição e Obrigatória")
      .required("A descrição e Obrigatória "),
  });
  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleSubmit(event) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis`,
        {
          Codigo: Code,
          Descricao: event.Descricao,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      ModalSuccess("O Perfil foi Alterado");

      // setErrorPage(true);
      ActionRetornarButton();
    } catch (error) {
      ModalErrorMessage(error);
    }
  }

  async function getData(code) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis/${code}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (response.data) {
        setRequestPerfis(response.data);
      }
      setLoad(true);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function ActionRetornarButton() {
    navigate(`/Lista-Perfis`);
  }
  useEffect(() => {
    const code = searchParams.get("code");
    if (code !== null) {
      getData(code);
      setCode(code);
    } else {
      setLoad(true);
    }
  }, []);
  return (
    <>
      {Load ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Perfis  - Módulo De Planejamento"
          />
          <div className="container mt-5">
            <h3>Editar perfil de acesso</h3>
          </div>
          <main className="listagem-inicial">
            <Formik
              validationSchema={schema}
              validateOnBlur={false}
              initialValues={{
                Descricao: requestPerfis.Descricao,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form className="container">
                  <div className=" pt-3 ">
                    <div className="formAdd ">
                      <FormGroup className="mb-3 p-1 formGroup w-75">
                        <label className="">
                          <span className="d-flex flex-row align-items-center">
                            Descrição
                          </span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          name="Descricao"
                        />
                        <ErrorMessage component="p" name="Descricao" />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="d-flex flex-row-reverse gap-3 mt-5">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={() => ActionRetornarButton()}>
                      Cancelar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
