import styles from "./ListaUsuarios.scss";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../services/api";
import { HiPencil } from "react-icons/hi";
import Select from "react-select";
import { GrDocumentUser } from "react-icons/gr";

import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button } from "react-bootstrap";

import dayjs from "dayjs";

export default function ListaUsuarios() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");

  const [erroPage, setErrorPage] = useState(true);
  const [DataUsuarios, setDataUsuarios] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);

  const [dataBusca, setDataBusca] = useState();
  const [checkboxBusca, setcheckboxBusca] = useState(false);
  const [statusBusca, setStatusBusca] = useState("");

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleRequestBusca(event) {
    console.log(event);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/usuarios?nome=${event.nome}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setDataUsuarios(response.data);
      } else {
        setDataUsuarios([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleClick() {
    navigate("/Lista-Perfis"); //
  }
  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/usuarios`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (response.data) {
        setDataUsuarios(response.data);
      }
      setErrorPage(false);
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,
        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {!erroPage ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Usuários - Módulo De Planejamento"
            button={{ text: "+ Gestão de perfis" }}
            onClick={handleClick}
          />

          <main className="listagem-inicial">
            <Formik
              initialValues={{
                nome: "",
              }}
              onSubmit={handleRequestBusca}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-5   ">
                    <div className="formPCA gap-5 ">
                      <div className=" w-25">
                        <Field
                          type="text"
                          name="nome"
                          placeholder="Nome"
                          className="form-control inputForm "
                        />
                      </div>

                      <div className="col">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container pt-5">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Código</th>
                    <th>Nome</th>

                    <th>Ações</th>
                  </tr>

                  {DataUsuarios?.map((usuario) => (
                    <tr key={usuario?.CodigoUsuario}>
                      <td>{usuario?.CodigoUsuario}</td>
                      <td>{usuario?.Nome}</td>

                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() =>
                              navigate(
                                `/Alterar-usuario?code=${usuario.Codigo}`
                              )
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
