import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function ModalSuccess(message) {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    icon: "success",
    title: "Sucesso",
    text: `${message}`,

    backdrop: false,
    showCancelButton: false,
    showConfirmButton: true,
  });

  return <></>;
}
