import React, { useContext } from "react";
import {
  BrowserRouter,
  Navigate,
  redirect,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import AuthContext from "../contexts/auth";
import Home from "../pages/home";

import { Auth } from "../pages/Auth";

import ListaPerfis from "../pages/Perfis/ListaPerfis";
import AlterarPerfilUsuario from "../pages/Usuarios/AlterarPerfilUsuario/AlterarPerfilUsuario";
import CadastraPerfil from "../pages/Perfis/CadastraPerfil/CadastraPerfil";
import EditarPerfil from "../pages/Perfis/EditarPerfil/EditarPerfil";
import ListarPermissoes from "../pages/Permissoes/ListarPermissoes";
import CadastraPermissoes from "../pages/Permissoes/CadastraPermissoes/CadastraPermissoes";
import ListaUsuarios from "../pages/Usuarios/ListaUsuarios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const AppRoutes = () => {
  const { state } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);
  const ListRoutes = [
    {
      route: "/Lista-Usuarios",
      component: <ListaUsuarios />,
    },
    {
      route: "/Alterar-usuario",
      component: <AlterarPerfilUsuario />,
    },
    {
      route: "/Lista-Perfis",
      component: <ListaPerfis />,
    },
    {
      route: "/Cadastra-Perfil",
      component: <CadastraPerfil />,
    },
    {
      route: "/Editar-Perfil",
      component: <EditarPerfil />,
    },

    {
      route: "/Listar-Permissoes",
      component: <ListarPermissoes />,
    },
    {
      route: "/Cadastra-Permissoes",
      component: <CadastraPermissoes />,
    },
  ];

  const Private = ({ children }) => {
    if (!state.isAuthenticated) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "A página atual não conseguiu carregar os dados;\n\n ",
        footer: "Erro: Token ausente ",
        backdrop: true,
        showCancelButton: false,
        showConfirmButton: false,
      });
      return <Navigate to="/" />;
    }
    return children;
  };
  const AuthValid = ({ children }) => {
    if (!state.isAuthenticated) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "A página atual não conseguiu carregar os dados;\n\n ",
        footer: "Erro: Token ausente ",
        backdrop: true,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    return children;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />

        {ListRoutes.map((route, index) => (
          <Route
            exact
            key={index}
            path={route.route}
            element={<Private>{route.component}</Private>}
          />
        ))}

        <Route
          exact
          path="/Modulos"
          element={
            <Private>
              <Home />
            </Private>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
