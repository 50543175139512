import styles from "./ListaPerfis.scss";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useContext, useEffect, useReducer, useState } from "react";
import api from "../../services/api";
import { HiPencil } from "react-icons/hi";
import Select from "react-select";
import { GrDocumentUser } from "react-icons/gr";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { DataUserContext } from "../../contexts/context";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers";
import AuthContext from "../../contexts/auth";
import * as jose from "jose";
import axios from "axios";
import { Button } from "react-bootstrap";

import dayjs from "dayjs";

export default function ListaPerfis() {
  const MySwal = withReactContent(Swal);
  const [TokenDecode, setTokenDecode] = useState("");

  const [erroPage, setErrorPage] = useState(true);
  const [requestPerfis, setrequestPerfis] = useState([]);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AuthContext);

  const [dataBusca, setDataBusca] = useState();
  const [checkboxBusca, setcheckboxBusca] = useState(false);
  const [statusBusca, setStatusBusca] = useState("");

  useEffect(() => {
    setTokenDecode(decodeJWT(state.token));
  }, [state.token]);
  function decodeJWT(token) {
    try {
      const decoded = jose.decodeJwt(token);
      return decoded;
    } catch (error) {
      return null;
    }
  }
  async function handleRequestBusca(event) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis?Descricao=${event.Descricao}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        setrequestPerfis(response.data);
      } else {
        setrequestPerfis([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleClick() {
    navigate("/Cadastra-Perfil"); //
  }
  async function getData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/v1.0/perfis`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      if (response.data) {
        const filteredData = response.data.filter(
          (item) => item.Status !== "Deletado"
        );
        setrequestPerfis(filteredData);
      }
      setErrorPage(false);
    } catch (error) {
      setErrorPage(true);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: `Desculpe, ocorreu um erro ao consumir o serviço. Contate nosso suporte (ERROR ${error?.response?.status}).`,

        backdrop: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  }
  function RemoveItem(key) {
    MySwal.fire({
      title: "Tem certeza?",
      text: "Você não será capaz de reverter isso!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, exclua!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeApi(requestPerfis[key].Codigo);
        const items = [...requestPerfis];
        items.splice(key, 1);
        setrequestPerfis(items);
        MySwal.fire("Excluído!", "O Perfil foi deletado.", "success");
      }
    });
  }
  async function removeApi(code) {
    try {
      await api.delete(`/api/v1.0/perfis/${code}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {!erroPage ? (
        <>
          <Navbar
            NomeLink={"Seleçao de Modulos"}
            link={"/Modulos"}
            titulo="Perfis - Módulo De Planejamento"
            button={{ text: "+ Cadastrar novo" }}
            button2={{ text: "Permissões" }}
            onClick={handleClick}
            onClick2={() => navigate("/Listar-Permissoes")}
          />

          <main className="listagem-inicial">
            <Formik
              initialValues={{
                Descricao: "",
              }}
              onSubmit={handleRequestBusca}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="container pt-5   ">
                    <div className="formPCA gap-5 ">
                      <div className=" w-25">
                        <Field
                          type="text"
                          name="Descricao"
                          placeholder="Descrição"
                          className="form-control inputForm "
                        />
                      </div>

                      <div className="col">
                        <button className="btn btn-primary " type="submit">
                          Pesquisar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="container pt-5">
              <table className="table">
                <tbody>
                  <tr className={styles.linha__tabbel_listagem}>
                    <th>Descricao</th>
                    <th>Papel</th>

                    <th>Ações</th>
                  </tr>

                  {requestPerfis?.map((item, index) => (
                    <tr key={item?.Codigo}>
                      <td>{item?.Descricao}</td>
                      <td>{item?.Papel}</td>

                      <td>
                        <div>
                          <HiPencil
                            type="button"
                            name="teste"
                            onClick={() =>
                              navigate(`/Editar-Perfil?code=${item.Codigo}`)
                            }
                          />
                          <MdCancel
                            type="button"
                            style={{ color: "red" }}
                            onClick={() => RemoveItem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
